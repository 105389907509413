<template>
  <div>
     <v-row>
         <v-col cols="12">
           <v-card>
            <v-card-text class="pa-4 subtitle-1">
             <v-form
                 ref="form"
                 v-model="valid"
                 autocomplete="off"
                 lazy-validation>
                <span class="text-info"><v-icon color="warning" left>mdi-account-cog-outline</v-icon> Account Information</span>
                 <v-divider  class="mb-5"/>
                 <v-autocomplete
                    v-model="account.role"
                     label="Account Type"
                     :items="ROLES"
                     dense
                     outlined
                     :rules="nameRules"
                     required
                 />
                 <v-text-field 
                     v-model.trim="account.fullname"
                     label="Fullname"
                     dense
                     outlined
                     :rules="nameRules"
                     required
                 />
                 <v-text-field 
                     v-model.trim="account.email"
                     label="Email"
                     dense
                     outlined
                 />
                 <v-text-field 
                     v-model.trim="account.contact_no"
                     label="Mobile No."
                     dense
                     outlined
                 />
                 <v-text-field 
                     v-if="account.role != 'Player'"
                     v-model.trim="account.commission"
                     label="Commission %"
                     required
                     dense
                     outlined
                 />
                   <v-autocomplete
                    v-model="account.status"
                     label="Account Status"
                     :items="['Active', 'Deactivated']"
                     dense
                     outlined
                     :rules="nameRules"
                     required
                 />
                  <!-- <div class="pa-2">
                      <span class="text-info"><v-icon color="warning" left>mdi-percent</v-icon> Commission</span>
                         <table class="va-table">
                           <tr v-for="(comm,i) in account.game_comm" :key="i">
                             <td style="width: 100px;" class="pa-2">{{comm.game}}</td>
                             <td class="pa-2"><input v-model="comm.comm"  style="width: 100px; text-align: center; border: 1px solid #555"/></td>
                           </tr>
                         </table>
                     </div> -->
               <br />
                 <span class="text-info"><v-icon color="warning" left>mdi-key-chain-variant</v-icon> Login Credentials</span>
                 <v-divider  class="mb-5"/>
                 <v-text-field 
                     v-model="account.username"
                     label="Login Username"
                     dense
                     outlined
                     :rules="nameRules"
                     required
                 />
                 <!-- <v-text-field 
                     v-if="state=='new'"
                     v-model="account.password"
                     label="Login Password"
                     dense
                     outlined
                     :rules="nameRules"
                     required
                 /> -->
                 <!-- <template v-else>
                     *** <v-icon right color="warning" small @click="sendform=true">mdi-pencil</v-icon>
                 </template> -->
             </v-form>
               <v-divider  class="mb-5"/>
         </v-card-text>
         
         <v-card-actions>
               
                  <v-spacer/>
             <v-btn color="success"   @click="validate()">Save</v-btn>
         </v-card-actions>
     </v-card>
         </v-col>
           <v-col cols="8">
               
           </v-col>
     </v-row>
 
 </div>
 </template>
 <script>
 import {
   mapMutations
 } from 'vuex'
   export default {
     data: () => ({
       form: false,
       valid: true,
       transform: false,
       passform: false,
       conform: false,
        game_comm:[{game: '3D', comm:0}, {game: '4D', comm:0},{game: 'LASTTWO', comm:0},{game: 'PICK3', comm:0}],
         search:'',
         role:'',
         from_date:'',
         to_date: '',
         date_range:"",
         account:{},
         selected_account:{},
         transactions:[],
         members:[],
          nameRules: [
             v => !!v || '*Required'
         ],
          headers: [
           { text: 'Account No.', value:"id"},
             { text: 'User', value:"username"},
             { text: 'Fullname', value:"fullname"},
            { text: 'Acct. Type'},
             { text: 'Last Login'}
         ],
     }),
     created() {
       this.PING()
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
       // this.userDetails()
     },
      mounted(){
        this.setLoggedIn(true)
         if (this.user == null) {
           this.$router.push('/')
         }else {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
           this.userDetails()
         }
     },
     computed:{
       isOnline(){
         return this.$store.state.is_online
       },
       user() {
         if(localStorage.user){
             return JSON.parse(localStorage.user)
         }
         return null
       },
      ROLES(){
         return ['Player', 'Agent','Coordinator']
        
      },
       ACCOUNTFILTERS(){
         if(!this.role) {
           return this.members
         }
             var acct = this.members.reduce((res, item)=>{
             if(item.role ==this.role) {
               res.push(item)
             }
             return res
         }, [] )
         return acct
       }
     },
     methods: {
       ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
       pasEvent(){
         this.passform = false
         this.conform = false
       },
        tEvent(){
         this.transform = false
       },
       fEvent(){
           this.form = false
       },
        validate () {
       if (this.$refs.form.validate()) {
          this.Save()
        }
       },
      filterFloat () {
       var comm = this.game_comm.reduce((res, item)=>{
           item.comm = this.$IsNum(item.comm)
           res.push(item)
           return res
       },[])
       return comm
     },
     Save(){
           var method = "user/update"
           this.account.commission =  this.$IsNum(this.account.commission)
          if(this.$IsNum(this.user.commission) <  this.account.commission) {
            this.VA_ALERT("error", "Commission too high!")
            return 
          }
           this.$http.post(method, this.account).then(response => {
               response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
           }).catch(e => {
               console.log(e)
           })
       },
      userDetails(){
           this.$http.post("user/details",{id: parseInt(this.$route.params.id)}).then(response => {
           //  console.log(response.data.account)
               if(response.data.status) {
                  this.account = response.data.account
                    if(this.account.game_comm == null) {
                    this.account.game_comm = this.game_comm
                  } else {
                   this.game_comm = this.account.game_comm 
                  }
               } 
           }).catch(e => {
             console.log(e.data)
           })
     },
     },
   }
 </script>